import { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { adminRoutes } from "../../utilities/authUtil";
import { Link } from "react-router-dom";
import moment from "moment"
import { toast, ToastContainer } from 'react-toastify';

export default function AdminHomepage() {

    const [activeTab, setActiveTab] = useState("users");

    const [userList, setUserList] = useState({
        documents: [],
        total_documents: 0,
        total_pages: 0
    });

    const [activePlanList, setActivePlanList] = useState({
        documents: [],
        total_documents: 0,
        total_pages: 0
    });

    const [pendingWithdrawalList, setPendingWithdrawalList] = useState({
        documents: [],
        total_documents: 0,
        total_pages: 0
    });

    const [usersPage, setUsersPage] = useState(1);
    const [activePlanPage, setActivePlanRange] = useState(1);
    const [pendingWithdrawalPage, setPendingWithdrawal] = useState(1);

    const [dashboardSummary, setDashboardSummary] = useState({
        total_users: 0,
        total_balance: 0,
        active_plans: 0,
        total_users_profit: 0,
        withdrawal_requests: 0
    });

    const fetchAdminDetails = async () => {
        const result = await adminRoutes.dashboard();
        console.log(result);
        setDashboardSummary(result.response.data);
    }

    async function renderList(activeTab = "all", pageNumber = "1") {
        switch (activeTab) {
            case "users":
                setUserList(await adminRoutes.userList(pageNumber));
                break;

            case "activePlans":
                setActivePlanList(await adminRoutes.activeUserPlans(pageNumber));
                break;

            case "pendingWithdrawal":
                setPendingWithdrawalList(await adminRoutes.pendingWithdrawalRequests(pageNumber));
                break;

            default:
                setUserList((await adminRoutes.userList(pageNumber)).response.data.users);
                setActivePlanList((await adminRoutes.activeUserPlans(pageNumber)).response.data.active_plans);
                setPendingWithdrawalList((await adminRoutes.pendingWithdrawalRequests(pageNumber)).response.data.pending_withdrawals);
                // console.log("User list", (await adminRoutes.userList(pageNumber)).response.data.users);
            // console.log("Active plans", (await adminRoutes.activeUserPlans(pageNumber)).response.data.active_plans);
            // console.log("Pending withdrawals", (await adminRoutes.pendingWithdrawalRequests(pageNumber)).response.data.pending_withdrawals);
        }
    }

    useEffect(() => {
        fetchAdminDetails();
        renderList();
    }, []);


    const copyText = (walletAddress) => {
        navigator.clipboard.writeText(walletAddress);
        toast.success("Wallet address copied!");
    }

    const withdrawalRequestVerdict = async (requestId, verdict) => {
        const withdrawalVerdictResponse = await adminRoutes.withdrawalVerdict();

        if(withdrawalVerdictResponse.response && withdrawalVerdictResponse.response.status == 200) {
            console.log(withdrawalVerdictResponse.response.data);
            fetchAdminDetails();
            renderList();
        }

    }


    const summaryStyling = "bg-white px-8 py-4 rounded-full border-[2px] border-solid border-green";

    return (
        <Layout>
            <ToastContainer />
            <div className="grid grid-cols-3 gap-6 flex-col md:flex-row">
                <div className={summaryStyling}>
                    Total users: {dashboardSummary.total_users}
                </div>

                <div className={summaryStyling}>
                    Total users balance: N{dashboardSummary.total_balance.toLocaleString()}
                </div>

                <div className={summaryStyling}>
                    Total active plans: {dashboardSummary.active_plans}
                </div>

                <div className={summaryStyling}>
                    Total users profit: N{dashboardSummary.total_users_profit.toLocaleString()}
                </div>

                <div className={summaryStyling}>
                    Total amount (users balance + users profit): N{(dashboardSummary.total_balance + dashboardSummary.total_users_profit).toLocaleString()}
                </div>

                <div className={summaryStyling}>
                    Total pending withdrawl requests: {dashboardSummary.withdrawal_requests}
                </div>
            </div>

            <div className="mb-4 mt-20 flex justify-evenly">
                <div className="cursor-pointer" style={activeTab == "users" ? { borderBottom: "4px solid green" } : { borderBlock: "0" }} onClick={() => { setActiveTab("users") }}>Users</div>
                <div className="cursor-pointer" style={activeTab == "activePlans" ? { borderBottom: "4px solid green" } : { borderBlock: "0" }} onClick={() => { setActiveTab("activePlans") }}>Active Plans</div>
                <div className="cursor-pointer" style={activeTab == "pendingWithdrawal" ? { borderBottom: "4px solid green" } : { borderBlock: "0" }} onClick={() => { setActiveTab("pendingWithdrawal") }}>Pending Withdrawal Requests</div>
            </div>

            <div>



            {
                    activeTab == "users" ? <table className="w-full">
                    <tr style={{textAlign: "left"}}>
                        <th>Full Name</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Register Date</th>
                    </tr>
                    {!userList ? "Loading..." : (userList.documents.length == 0 ? "No active plan" : "")}
                    {userList.documents.length > 0 ? (
                        userList.documents.map((activePlan) => {
                            return (
                                <tr key={activePlan._id}>
                                    <td className="py-4">{activePlan.full_name}</td>
                                    <td>{activePlan.username}</td>
                                    <td>{activePlan.email}</td>
                                    <td>{moment(activePlan.createdAt).format("Do MMMM YYYY")}</td>
                                </tr>
                            );
                        })
                    ) : ""}
                </table> : ""
                }



                {
                    activeTab == "activePlans" ? <table className="w-full">
                    <tr style={{textAlign: "left"}}>
                        <th>Plan</th>
                        <th>Number of days</th>
                        <th>Profit</th>
                        <th>Plan Purchase Date</th>
                        <th>Maturity Date</th>
                    </tr>
                    {!activePlanList ? "Loading..." : (activePlanList.documents.length == 0 ? "No active plan" : "")}
                    {activePlanList.documents.length > 0 ? (
                        activePlanList.documents.map((activePlan) => {
                            return (
                                <tr key={activePlan._id}>
                                    <td className="py-4">{activePlan.plan} plan</td>
                                    <td>{activePlan.no_of_days} days in total</td>
                                    <td>N{activePlan.profit.toLocaleString()}</td>
                                    <td>{moment(activePlan.createdAt).format("Do MMMM YYYY")}</td>
                                    <td>{moment(activePlan.future_return_date).format("Do MMMM YYYY")}</td>
                                </tr>
                            );
                        })
                    ) : ""}
                </table>
                        : ""
                }

                

                {
                    activeTab == "pendingWithdrawal" ? <table className="w-full">
                    <tr style={{textAlign: "left"}}>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Wallet type</th>
                        <th>Wallet Address</th>
                        <th>Request Date</th>
                        <th>Actions</th>
                    </tr>
                    {!pendingWithdrawalList ? "Loading..." : (pendingWithdrawalList.documents.length == 0 ? "No active plan" : "")}
                    {pendingWithdrawalList.documents.length > 0 ? (
                        pendingWithdrawalList.documents.map((activePlan) => {
                            return (
                                <tr key={activePlan._id}>
                                    <td className="py-4">{activePlan.withdrawal_status[0].toUpperCase() + activePlan.withdrawal_status.slice(1)}</td>
                                    <td>N{activePlan.amount.toLocaleString()}</td>
                                    <td>{activePlan.wallet_type.split("-").join(" ")}</td>
                                    <td>
                                        <div className="flex items-center gap-4">
                                            <div>{activePlan.wallet_address}</div>
                                            <button onClick={() => {copyText(activePlan.wallet_address)}} className="border-2 border-dashed border-green py-1 px-4 text-green font-bold rounded-xl">Copy</button>
                                        </div>
                                    </td>
                                    <td>{moment(activePlan.createdAt).format("Do MMMM YYYY")}</td>
                                    <td className="flex items-center gap-4">
                                        <button onClick={() => {withdrawalRequestVerdict(activePlan._id, "approved")}} className="bg-green border-0 py-2 px-8 text-white font-bold rounded-xl">Approve</button>
                                        <button onClick={() => {withdrawalRequestVerdict(activePlan._id, "declined")}} className="bg-[red] border-0 py-2 px-8 text-white font-bold rounded-xl">Reject</button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : ""}
                </table> : ""
                }
            </div>
        </Layout>
    );
}