import { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { PaystackButton } from "react-paystack"
import { getUserProfile, updateUserBalance, purchasePlan } from "../../utilities/authUtil";
import Joi from "joi";
import { toast, ToastContainer } from 'react-toastify';

export default function Plans() {

    const [plans, setPlans] = useState("");
    const [terms, setTerms] = useState("");
    const [days, setDays] = useState("");
    const [amount, setAmount] = useState("");
    const [profitDetails, setProfitDetails] = useState({
        capital: "",
        plan: "",
        term: "",
        no_of_days: "",
        profit: 0
    });


    let checkForError = () => {
        console.log(plans);
        console.log(amount);
        let error = { error: null, value: null, warning: null }
        if (plans == "Regular") {
            error = (Joi.number().min(500).max(5000)).validate(parseFloat(amount))
            error.message = "Amount must be between N500 and N5,000";
        } else if (plans == "Classic") {
            error = (Joi.number().min(5000).max(100000)).validate(parseFloat(amount))
            error.message = "Amount must be between N5,000 and N100,000";
        } else if (plans == "Silver") {
            error = (Joi.number().min(100000).max(500000)).validate(parseFloat(amount))
            error.message = "Amount must be between N100,000 and N500,000";
        } else if (plans == "Bronze") {
            error = (Joi.number().min(500000).max(1000000)).validate(parseFloat(amount))
            error.message = "Amount must be between N500,000 and N1,000,000";
        } else if (plans == "Bronze") {
            error = (Joi.number().min(1000000).max(5000000)).validate(parseFloat(amount))
            error.message = "Amount must be between N1,000,000 and N5,000,000";
        }

        if (error && error.error) {
            toast.error(error.message);
            console.log(error);
            return true;
        }

        return false;

    }

    useEffect(() => {
        checkForError();
    }, [plans, amount]);


    const subscribeToAPlan = async () => {
        let anyError = checkForError();

        if (anyError) return;

        const response = await purchasePlan(amount, plans, terms, days);
        if (!response.error) {
            const data = response.response.data;
            console.log("Subscription response:", response);
            // setProfitDetails(data);
            if(response.response.status == 200 && data.isSuccessful == true) {
                toast.success("Subscription Successful");
            } else {
                toast.error(data.message);
            }

        }
    }


    return (
        <Layout title="Title" description="Description">
            <ToastContainer />
            <div className="flex flex-col gap-8 items-start w-full">

                <div className="flex flex-col gap-8 items-start w-full md:w-1/4">

                    <div className="w-full">
                        <div className="font-bold">Select Plan:</div>
                        <input type="number" className="py-2 px-1 w-full" placeholder="Input an amount" onChange={(e) => { setAmount(e.target.value) }} />
                    </div>

                    <div className="w-full">
                        <div className="font-bold">Select Plan:</div>
                        <select className="py-2 px-1 w-full" onChange={(e) => { setPlans(e.target.value) }}>
                            <option value="">--</option>
                            <option value="Regular">Regular</option>
                            <option value="Classic">Classic</option>
                            <option value="Silver">Silver</option>
                            <option value="Bronze">Bronze</option>
                            <option value="Gold">Gold</option>
                        </select>
                    </div>

                    <div className="w-full">
                        <div className="font-bold">Select Term:</div>
                        <select className="py-2 px-1 w-full" onChange={(e) => { setTerms(e.target.value) }}>
                            <option value="">--</option>
                            <option value="shortTerm">Short Term</option>
                            <option value="midTerm">Mid Term</option>
                            <option value="longTerm">Long Term</option>
                            <option value="boosterTerm">Booster Term</option>
                        </select>
                    </div>


                    <div className="w-full">
                        <div className="font-bold">Day length:</div>
                        {terms == "shortTerm" && (
                            <select className="py-2 px-1 w-full" onChange={(e) => { setDays(e.target.value) }}>
                                <option value="">--</option>
                                <option value="5">5 Days</option>
                                <option value="10">10 days</option>
                                <option value="15">15 days</option>
                            </select>
                        )}

                        {terms == "midTerm" && (
                            <select className="py-2 px-1 w-full" onChange={(e) => { setDays(e.target.value) }}>
                                <option value="">--</option>
                                <option value="30">30 Days</option>
                                <option value="60">60 days</option>
                                <option value="90">90 days</option>
                            </select>
                        )}

                        {terms == "longTerm" && (
                            <select className="py-2 px-1 w-full" onChange={(e) => { setDays(e.target.value) }}>
                                <option value="">--</option>
                                <option value="120">120 Days</option>
                                <option value="150">150 days</option>
                                <option value="180">180 days</option>
                            </select>
                        )}

                        {terms == "boosterTerm" && (
                            <select className="py-2 px-1 w-full" onChange={(e) => { setDays(e.target.value) }}>
                                <option value="">--</option>
                                <option value="210">210 Days</option>
                                <option value="240">240 days</option>
                                <option value="270">270 days</option>
                            </select>
                        )}

                    </div>

                    {amount.length <= 0 ? <button className="py-2 px-1 w-full text-white bg-[#bbb] border-0 font-bold">Subscribe</button> : <button className="py-2 px-1 w-1/4 text-white bg-blue border-0 font-bold" onClick={subscribeToAPlan}>Subscribe</button>}

                </div>


            </div>
        </Layout>
    );
}