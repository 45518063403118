import { backendV1, backendV1WithAuth, paystackRequest } from "./axiosFlavours";


async function registerUser(payload) {
    try {
        const response = await backendV1.post("auth/register", payload);

        return {
            succesful: true,
            response: response,
            error: null
        }

    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
};


async function loginUser(payload) {
    try {
        const response = await backendV1.post("auth/login", payload);

        return {
            succesful: true,
            response: response,
            error: null
        }

    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}


async function verifEmail(payload) {
    try {
        const response = await backendV1.post("auth/verify-email", payload);

        return {
            succesful: true,
            response: response,
            error: null
        }
    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}

async function getUserProfile() {
    try {
        const response = await backendV1WithAuth.get("user/profile");

        return {
            succesful: true,
            response: response,
            error: null
        }
    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}

async function updateUserBalance(payload) {
    try {
        const response = await backendV1WithAuth.post("user/update-balance", payload);

        return {
            succesful: true,
            response: response,
            error: null
        }
    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}

async function getUserTransactions(page = 1, limit = 10) {
    try {
        const response = await backendV1WithAuth.get(`user/transactions/${page}/${limit}`);

        return {
            succesful: true,
            response: response,
            error: null
        }
    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}


async function getBankList() {
    try {
        const response = await paystackRequest.get("https://api.paystack.co/bank?country=nigeria");

        return {
            succesful: true,
            response: response,
            error: null
        }

    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}

async function resolveAccountNumber(accountNumber, bankCode) {
    try {
        const response = await backendV1WithAuth.get(`user/resolve-account-details/${accountNumber}/${bankCode}`);

        return {
            succesful: true,
            response: response,
            error: null
        }

    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}


async function initiateBankTransfer(accountName, accountNumber, bankCode, amount, type = "nuban", currency = "NGN") {
    try {
        const response = await backendV1WithAuth.post(`user/initiate-transfer`, {
            name: accountName,
            account_number: accountNumber,
            bank_code: bankCode,
            type, currency,
            amount
        });

        return {
            succesful: true,
            response: response,
            error: null
        }

    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}

async function initializeWithdrawalRequest(wallet_type, wallet_address, amount) {
    try {
        const response = await backendV1WithAuth.post(`user/withdrawalrequest`, {
            wallet_address, wallet_type, amount
        });

        return {
            succesful: true,
            response: response,
            error: null
        }

    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}

async function calculateProfit(capital, plan, term, no_of_days) {
    try {
        const response = await backendV1WithAuth.post(`user/calculator`, {
            capital, plan, term, no_of_days
        });

        return {
            succesful: true,
            response: response,
            error: null
        }

    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}



async function purchasePlan(capital, plan, term, no_of_days) {
    try {
        const response = await backendV1WithAuth.post(`user/purchaseplan`, {
            capital, plan, term, no_of_days
        });

        return {
            succesful: true,
            response: response,
            error: null
        }

    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}

async function userAceivePlans() {
    try {
        const response = await backendV1WithAuth.get(`user/useractiveplans`);

        return {
            succesful: true,
            response: response,
            error: null
        }

    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}

async function userPlansHistory() {
    try {
        const response = await backendV1WithAuth.get(`user/userplanshistory`);

        return {
            succesful: true,
            response: response,
            error: null
        }

    } catch (error) {
        return {
            succesful: false,
            response: null,
            error
        }
    }
}


const adminRoutes = {
    async dashboard() {
        try {
            const response = await backendV1WithAuth.get(`admin/admindashboard`);
    
            return {
                succesful: true,
                response: response,
                error: null
            }
    
        } catch (error) {
            return {
                succesful: false,
                response: null,
                error
            }
        }
    },
    async activeUserPlans(page, limit=10) {
        try {
            const response = await backendV1WithAuth.get(`admin/user-active-plans/${page}/${limit}`);
    
            return {
                succesful: true,
                response: response,
                error: null
            }
    
        } catch (error) {
            return {
                succesful: false,
                response: null,
                error
            }
        }
    },
    async userList(page, limit=10) {
        try {
            const response = await backendV1WithAuth.get(`admin/users-list/${page}/${limit}`);
    
            return {
                succesful: true,
                response: response,
                error: null
            }
    
        } catch (error) {
            return {
                succesful: false,
                response: null,
                error
            }
        }
    },
    async pendingWithdrawalRequests(page, limit=10) {
        try {
            const response = await backendV1WithAuth.get(`admin/pending-withdrawal-requests/${page}/${limit}`);
    
            return {
                succesful: true,
                response: response,
                error: null
            }
    
        } catch (error) {
            return {
                succesful: false,
                response: null,
                error
            }
        }
    },
    async singleUser(userId) {
        try {
            const response = await backendV1WithAuth.get(`admin/user/${userId}`);
    
            return {
                succesful: true,
                response: response,
                error: null
            }
    
        } catch (error) {
            return {
                succesful: false,
                response: null,
                error
            }
        }
    },
    async singleWithdrawalRequests(requestId) {
        try {
            const response = await backendV1WithAuth.get(`admin/withdrawal-request/${requestId}`);
    
            return {
                succesful: true,
                response: response,
                error: null
            }
    
        } catch (error) {
            return {
                succesful: false,
                response: null,
                error
            }
        }
    },
    async withdrawalVerdict(requestId, verdict) {
        try {
            const response = await backendV1WithAuth.get(`admin/withdrawal-verdict/${requestId}/${verdict}`);
    
            return {
                succesful: true,
                response: response,
                error: null
            }
    
        } catch (error) {
            return {
                succesful: false,
                response: null,
                error
            }
        }
    }
};

export {
    registerUser,
    loginUser,
    verifEmail,
    getUserProfile,
    updateUserBalance,
    getUserTransactions,
    getBankList,
    resolveAccountNumber,
    initiateBankTransfer,
    calculateProfit,
    purchasePlan,
    userAceivePlans,
    userPlansHistory,
    initializeWithdrawalRequest,
    adminRoutes
}
