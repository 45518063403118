import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { registerUser } from "../utilities/authUtil";
import joi, { allow } from "joi";
import { ToastContainer, toast } from 'react-toastify';


export default function Register() {

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [emailConfirm, setEmailConfirm] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [BNBWallet, setBNBWallet] = useState("");
    const [BTCWallet, setBTCWallet] = useState("");
    const [isTermsChecked, setCheckTerms] = useState(false);
    const [registerText, setRegisterText] = useState("Register");

    const navigate = useNavigate();
    
    const registerButton = async () => {

        try {
            setRegisterText("Please wait...");
            const registerForm = {
                fullName,
                email,
                emailConfirm,
                username,
                password,
                passwordConfirm,
                BNBWallet,
                BTCWallet
            };
    
            const registerValidationSchema = joi.object({
                fullName: joi.string().min(8).required(),
                email: joi.string().email({tlds: {allow: false}}).required(),
                emailConfirm: joi.string().required().valid(joi.ref('email')),
                username: joi.string().min(8).required(),
                password: joi.string().min(8).alphanum().required(),
                passwordConfirm: joi.string().required().valid(joi.ref('password')),
                BNBWallet: joi.optional(),
                BTCWallet: joi.optional(),
                isTermsChecked: joi.boolean().valid(true).messages({
                    "any.only": "Kindly agree to our terms and conditions"
                })
            });
    
            const {error: formValidationError} = registerValidationSchema.validate(registerForm);
    
            if(formValidationError) {
                toast.error(formValidationError.message);
                return;
            }
    
            const {succesful, response, error} = await registerUser({
                username,
                email,
                full_name: fullName,
                btc_wallet: BTCWallet,
                bnb_wallet: BNBWallet,
                password
            });

            console.log(response.status);
            console.log(response.data);
    
            if(response.data.status_code == 201 && succesful == true && !error) {
                navigate("/login");
            } else {
                // console.log(error);
                toast.error(response.data.detail);
            }
     
        } catch (error) {
            toast.error(error.message || "An error occurred!");
        } finally {
            setRegisterText("Register");
        }

        
        
    }

    const inputStyle = "px-4 py-4 border-none rounded-[4px] bg-black/[0.1] outline-none";
    const loginButtonStyle = " bg-green p-4 border-none font-bold text-white rounded-[4px] text-[16px]";

    return(
        <div className="h-screen w-full flex justify-center items-center pt-40">
            <ToastContainer />
            <div className="w-[90%] md:w-1/2 xl:w-1/3 flex flex-col gap-10 pt-[200px] pb-10">

                <h2>Registration Form</h2>

                <div className="flex flex-col border-box">
                    <small className="font-bold">Full Name</small>
                <input type="text" placeholder="John Doe" className={inputStyle} onChange={(e) => {setFullName(e.target.value)}} autoFocus />
                </div>

                <div className="flex flex-col border-box">
                    <small className="font-bold">Email</small>
                <input type="email" placeholder="user@example.com" className={inputStyle} onChange={(e) => {setEmail(e.target.value)}}  />
                </div>

                <div className="flex flex-col border-box">
                    <small className="font-bold">Retype Email</small>
                <input type="email" placeholder="user@example.com" className={inputStyle} onChange={(e) => {setEmailConfirm(e.target.value)}} />
                </div>

                <div className="flex flex-col border-box">
                    <small className="font-bold">Username</small>
                <input type="text" placeholder="john_doe" className={inputStyle} onChange={(e) => {setUsername(e.target.value)}} />
                </div>

                <div className="flex flex-col border-box">
                    <small className="font-bold">Password</small>
                <input type="password" placeholder="********" className={inputStyle} onChange={(e) => {setPassword(e.target.value)}} />
                </div>

                <div className="flex flex-col border-box">
                    <small className="font-bold">Retype password</small>
                <input type="password" placeholder="********" className={inputStyle} onChange={(e) => {setPasswordConfirm(e.target.value)}} />
                </div>

                <div className="flex flex-col border-box">
                    <small className="font-bold">BNB Wallet Address</small>
                <input type="text" placeholder="Your BNB walet address here" className={inputStyle} onChange={(e) => {setBNBWallet(e.target.value)}} />
                </div>

                <div className="flex flex-col border-box">
                    <small className="font-bold">BTC Wallet Address</small>
                <input type="text" placeholder="Your BNB walet address here" className={inputStyle} onChange={(e) => {setBTCWallet(e.target.value)}} />
                </div>

                <div><input type="checkbox" checked={isTermsChecked} onClick={() => {setCheckTerms(!isTermsChecked)}} /> I agree to the terms and condition</div>

                <button className={loginButtonStyle} onClick={registerButton}>{registerText}</button>

                <div className="text-center">
                    <small>Already have an account? <Link to="/login" className="no-underline text-blue">Login</Link></small>
                </div>
            </div>
        </div>
    );
}