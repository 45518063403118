import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import joi from "joi";
import { verifEmail } from "../utilities/authUtil";
import { toast, ToastContainer } from 'react-toastify';


export default function verifEmailEmailPage() {

    const [OTP, setOTP] = useState("");

    const [verifyButton, setVerifyButton] = useState("Verify");

    const routerParams = useParams();

    const navigate = useNavigate();

    const verify = async () => {
        try {
            setVerifyButton("Verifying Email... Please wait");
            const emailVerificationForm = {OTP};
            const emailVerificationSchema = joi.object({
                OTP: joi.string().length(6).required()
            });

            const {error: formValidationError} = emailVerificationSchema.validate(emailVerificationForm);

            if(formValidationError) {
                toast.error(formValidationError.message);
                return;
            }

            const {error, response, succesful} = await verifEmail({otp: emailVerificationForm.OTP, unique_id: routerParams.unique_id});

            console.log(response);
            if(response.data.status_code == 200 && succesful == true && !error) {
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("role", response.data.user_details.role);
                if(response.data.user_details.role == "user") {
                    navigate("/dashboard");
                } else {
                    navigate("/admindashboard");
                }
            } else {
                console.log(response);
                toast.error(response.data.detail);
            }

        } catch (error) {
            toast.error(error.message || "An error occurred!");
        } finally {
            setVerifyButton("Verify");
        }
    }

    const inputStyle = " px-4 py-4 border-none rounded-[4px] bg-black/[0.1]";
    const loginButtonStyle = "bg-blue p-4 border-none font-bold text-white rounded-[4px] text-[16px]";

    return(
        <div className="h-screen w-full flex justify-center items-center">
            <ToastContainer />
            <div className="w-[90%] md:w-1/2 xl:w-1/3 flex flex-col gap-10">

                {/* <img /> */}

                <h2>Verify Email</h2>

                <div className="flex flex-col border-box">
                    <small className="font-bold">OTP</small>
                <input type="text" placeholder="example.com" className={inputStyle} onChange={(e) => {setOTP(e.target.value)}} />
                </div>

                <button className={loginButtonStyle} onClick={verify}>{verifyButton}</button>


            </div>
        </div>
    );
}