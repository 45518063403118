import { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { getUserProfile } from "../../utilities/authUtil";

export default function Profile() {

    const [profile, setProfile] = useState({
        full_name: null,
        username: null,
        email: null
    });

    const userProfile = async () => {
        const profile = await getUserProfile();
        setProfile(profile.response.data);
        console.log(profile.response.data);
    }

    useEffect(() => {
        userProfile();
        
    }, []);

    return (
        <Layout>
            {profile.full_name ? (
                <div className="flex flex-col gap-8">

                    <div className="h-[100px] w-[100px] rounded-full bg-[#ccc] flex justify-center text-[40px] items-center font-bold">
                        {(profile.full_name).split(" ").map(n => n[0]).join(" ")}
                    </div>

                    <div>
                        Full Name: {profile.full_name}
                    </div>

                    <div>
                        Username: {profile.username}
                    </div>

                    <div>
                        Email: {profile.email}
                    </div>
                </div>
            ) : "Loading..."}

        </Layout>
    );
}