import { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import Joi from "joi";
import { toast, ToastContainer } from 'react-toastify';
import { getBankList, initiateBankTransfer, resolveAccountNumber, initializeWithdrawalRequest } from "../../utilities/authUtil";

export default function Withdraw() {

    const [amount, setAmount] = useState("");
    const [paymentOption, setPaymentOption] = useState("");
    const [bankList, setBankList] = useState([]);
    const [accountNumber, setAccountNumber] = useState("");
    const [bankCode, setBankCode] = useState("");
    const [accountName, setAccountName] = useState("");
    const [walletAddress, setWalletAddress] = useState("");

    const listOfBanks = async () => {
        const banks = await getBankList();
        if(banks.response) {
            setBankList(banks.response.data.data);
        } else {
            console.log(banks.error.code);
            if(banks.error.code == "ERR_NETWORK") {
                toast.error("Network Disconnected");
            }
        }
        // console.log(banks.response.data.data);
    }

    const resolveAccountDetails = async () => {        
        try {

            const accountDetails = await resolveAccountNumber(accountNumber, bankCode);
            console.log(accountDetails.response.data.data.account_name);
            setAccountName(accountDetails.response.data.data.account_name);
            
        } catch (error) {
            // console.log(error);
            setAccountName("Account name could not be resolved");
        }
    }

    useEffect(() => {
        listOfBanks();
    }, []);

    useEffect(() => {
        if(accountNumber.length == 10) {
            setAccountName("Loading...");
            resolveAccountDetails();
        } else {
            setAccountName("");
        }
    }, [accountNumber]);

    const withdraw = async () => {
        const validation = Joi.object({
            amount: Joi.number().min(3000).required(),
            paymentOption: Joi.string().valid("bank", "btc-wallet", "bnb-wallet")
        });

        const { error } = validation.validate({
            amount: parseFloat(amount),
            paymentOption
        });

        if (error) return toast.error(error.message);

        if(paymentOption == "bank") {
            const transferResponse = await initiateBankTransfer(accountName, accountNumber, bankCode, amount);
            console.log(transferResponse);
        } else {
            // Create a request in which the admin has to approve
            const makeWithdrawalRequest = await initializeWithdrawalRequest(paymentOption, walletAddress, amount);
            console.log("Withdrawal request:");
            if(makeWithdrawalRequest.response.data.isSuccessful == true) {
                toast.success("Withdrawal Request Made");
            }
        }

    }

    return (
        <Layout>
            <ToastContainer />
            <div>

                <div className="flex flex-col gap-4 items-start mb-8">
                    <div className="font-bold">Amount:</div>
                    <input type="text" className="py-2 px-1 w-full md:w-1/4" placeholder="should not be less than N3000" onChange={(e) => { setAmount(e.target.value) }} />
                    <div className="mt-4 font-bold">Withdraw option:</div>
                    <select className="py-2 px-1 w-full md:w-1/4" onChange={(e) => { setPaymentOption(e.target.value) }}>
                        <option value="">Select</option>
                        <option value="bank">Bank</option>
                        <option value="bnb-wallet">BNB Wallet</option>
                        <option value="btc-wallet">BTC Wallet</option>
                    </select>
                </div>


                <div className={`flex flex-col gap-4 items-start mb-8 ${paymentOption == "bank" ? "block" : "hidden"}`}>
                    <div className="font-bold">Select Bank:</div>
                    <select className="py-2 px-1 w-full md:w-1/4" onChange={(e) => {setBankCode(e.target.value)}}>
                        {bankList.map((bank, index) => {
                            if(bank.currency == "NGN") {
                                return (
                                    <option value={bank.code}>{bank.name}</option>
                                );
                            }
                        })}
                    </select>
                    <div className="font-bold">Account Number:</div>
                    <input type="text" className="py-2 px-1 w-full md:w-1/4" onChange={(e) => {setAccountNumber(e.target.value)}} />
                    <div>{accountName}</div>
                </div>

                <div className={`flex flex-col gap-4 items-start mb-8 ${paymentOption != "bank" && paymentOption != "" ? "block" : "hidden"}`}>
                <div className="font-bold">Wallet Address:</div>
                    <input type="text" className="py-2 px-1 w-full md:w-1/4" onChange={(e) => {setWalletAddress(e.target.value)}} />
                </div>

                <button className="py-2 px-1 w-full md:w-1/4 text-white bg-blue border-0 font-bold" onClick={withdraw}>Withdraw</button>
            </div>

        </Layout>
    );
}