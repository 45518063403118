import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";
import Home from './pages/home';
// import PageNotFound from "./pages/pageNotFound";
import 'alertifyjs/build/css/alertify.css';
import "animate.css/animate.min.css";
import Login from './pages/login';
import Register from './pages/register';
import VerifyEmailPage from './pages/verifyEmail';
import UserHomepage from './pages/dashboard/home';
import AdminHomepage from './pages/admindashboard/home';
import Deposit from './pages/dashboard/deposit';
import Withdraw from './pages/dashboard/withdraw';
import Profile from './pages/dashboard/profile';
import Plans from './pages/dashboard/plans';
import ProfitCalculator from './pages/dashboard/profitcalculator';
import PlansHistory from './pages/dashboard/planhistory';
import 'react-toastify/dist/ReactToastify.css';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);



const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  // {
  //   path: "/about",
  //   element: <About />,
  // },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/verify-email/:unique_id",
    element: <VerifyEmailPage />,
  },
  {
    path: "/dashboard",
    element: <UserHomepage />,
  },
  {
    path: "/dashboard/profile",
    element: <Profile />
  },
  {
    path: "/dashboard/withdraw",
    element: <Withdraw />
  },
  {
    path: "/dashboard/deposit",
    element: <Deposit />
  },
  {
    path: "/dashboard/plans",
    element: <Plans />
  },
  {
    path: "/dashboard/planshistory",
    element: <PlansHistory />
  },
  {
    path: "/dashboard/profitcalculator",
    element: <ProfitCalculator />
  },
  {
    path: "/admindashboard",
    element: <AdminHomepage />,
    children: [
      {
        path: "profile",
        element: <AdminHomepage />
      }
    ]
  },
  // {
  //   path: "/faqs",
  //   element: <Faqs />,
  // },
  // {
  //   path: "/privacy-and-cookies-policy",
  //   element: <PrivacyAndCookiesPolicy />,
  // },
  // {
  //   path: "/terms-of-use-and-contracting",
  //   element: <TermsOfUseAndContracting />
  // },
  // {
  //   path: "/stores-in-a-city/:city",
  //   element: <StroesInACity />
  // },
  // {
  //   path: "/*",
  //   element: <PageNotFound />
  // }
]);
// <Route path="*" component={PageNotFound} />

// All `Portal`-related components need to have the the main app wrapper element as a container
// so that the are in the subtree under the element used in the `important` option of the Tailwind's config.
const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
          <RouterProvider router={router} />
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
