import { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { PaystackButton } from "react-paystack"
import { getUserProfile, updateUserBalance, purchasePlan, userAceivePlans, userPlansHistory } from "../../utilities/authUtil";
import Joi from "joi";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';

export default function PlansHistory() {

    const [activePlans, setActivePlans] = useState({
        documents: [],
        total_documents: 0,
        total_pages: 1
    });
    const [plansHistory, setPlansHistory] = useState({
        documents: [],
        total_documents: 0,
        total_pages: 1
    });
    const [activeTab, setActiveTab] = useState("activeplantab");
    const [amount, setAmount] = useState("");


    const loadActivePlans = async () => {
        const activePlansFetched = await userAceivePlans();
        if (activePlansFetched.response.data.isSuccessful) {
            setActivePlans(activePlansFetched.response.data.user_active_plans);
            console.log(activePlansFetched.response.data.user_active_plans);
        }

        const userPlanHistory = await userPlansHistory();
        if (userPlanHistory.response.data.isSuccessful) {
            setPlansHistory(userPlanHistory.response.data.user_active_plans);
            console.log("Plan history starts here", userPlanHistory.response.data.user_active_plans, "Ends here");
        }
    }

    useEffect(() => {
        loadActivePlans();
    }, []);


    return (
        <Layout title="Title" description="Description">
            <ToastContainer />
            <div className="flex flex-col gap-8 w-full">
                <div className="flex justify-evenly">
                    <div className={`${activeTab == "activeplantab" ? "text-green" : "text-green bg-[#ccc]"} py-8 px-4 font-bold text-[24px] w-full text-center`} onClick={() => { setActiveTab("activeplantab") }}>Active Plans</div>
                    <div className={`${activeTab == "historyplantab" ? "text-green" : "text-green bg-[#ccc]"} py-8 px-4 font-bold text-[24px] w-full text-center`} onClick={() => { setActiveTab("historyplantab") }}>Plans History</div>
                </div>
                <div className={`${activeTab == "activeplantab" ? "block" : "hidden"}`}>
                    Active Plans
                    <div>
                        <div>
                            Nsxt Previous {activePlans.documents.length}
                        </div>
                        <table>
                            <tr>
                                <th>Plan</th>
                                <th>Capital</th>
                                <th>Start Date</th>
                                <th>Total Return Amount</th>
                                <th>End Date</th>
                            </tr>
                            {!activePlans ? "Loading..." : (activePlans.documents.length == 0 ? "No active plan" : "")}
                            {activePlans.documents.length > 0 ? (
                                activePlans.documents.map((activePlan, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{activePlan.plan}</td>
                                            <td>N{activePlan.capital}</td>
                                            <td>{moment(activePlan.createdAt).format("Do MMMM YYYY")}</td>
                                            <td>N{activePlan.total_return_amount}</td>
                                            <td>{moment(activePlan.future_return_date).format("Do MMMM YYYY")}</td>
                                        </tr>
                                    );
                                })
                            ) : ""}
                        </table>
                    </div>
                </div>

                <div className={`${activeTab == "historyplantab" ? "block" : "hidden"}`}>
                    Plans History
                    <div>
                        <div>
                            Nsxt Previous {plansHistory.documents.length}
                        </div>
                        <table>
                            <tr>
                                <th>Plan</th>
                                <th>Capital</th>
                                <th>Start Date</th>
                                <th>Total Return Amount</th>
                                <th>End Date</th>
                            </tr>
                            {!plansHistory ? "Loading..." : (plansHistory.documents.length == 0 ? "No active plan" : "")}
                            {plansHistory.documents.length > 0 ? (
                                plansHistory.documents.map((activePlan, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{activePlan.plan}</td>
                                            <td>N{activePlan.capital}</td>
                                            <td>{moment(activePlan.createdAt).format("Do MMMM YYYY")}</td>
                                            <td>N{activePlan.total_return_amount}</td>
                                            <td>{moment(activePlan.future_return_date).format("Do MMMM YYYY")}</td>
                                        </tr>
                                    );
                                })
                            ) : ""}
                        </table>
                    </div>
                </div>
            </div>
        </Layout>
    );
}