import { useNavigate } from "react-router-dom";
import AdminSideBar from "../components/AdminSidebar";
import UserSideBar from "../components/UserSidebar";
import { useEffect, useState } from "react";
import { getUserProfile } from "../utilities/authUtil";

export default function Layout(props) {

    const navigate = useNavigate()

    const [menu, setMenu] = useState(false);

    const [fullName, setFullName] = useState("");

    if(!localStorage.getItem("token")) {
        navigate("/login");
    }

    console.log(props);

    const userProfile = async () => {
        const profile = await getUserProfile();
        setFullName(profile.response.data.full_name);
    }

    useEffect(() => {
        userProfile();
    }, []);
    

    return (
    <div className="w-full h-screen flex box-border">
        <div className={`absolute md:relative ${menu == true ? "block" : "hidden"} md:block w-[80%] md:w-[20%] h-full py-10 px-8 bg-blue md:bg-green/[0.8]`}>
            {localStorage.getItem("role") == "user" ? <UserSideBar /> : <AdminSideBar />}
        </div>
        <div className="w-full md:w-[80%] h-full flex flex-col">
            <div className="h-[10%] flex items-center px-4 justify-between">
                <div>
                    <div className="font-bold text-[24px]">{props.title ? props.title : "Dashboard"}</div>
                    <div className="text-[grey]">{props.description ? props.description : "The beginning of your experience"}</div>
                </div>
                <div className="flex items-center gap-4 bg-[#eee] px-4 py-2 rounded-[50px]">
                    <div className="flex items-center gap-4">
                    <div>{fullName}</div>
                    <div className="h-[50px] w-[50px] rounded-full bg-[#ccc] flex justify-center items-center font-bold">
                        {fullName.split(" ").map(n => n[0]).join(" ")}
                    </div>
                    </div>
                    <button onClick={() => {setMenu(!menu)}} className="bg-white border-none flex md:hidden flex-col gap-[5px]">
                        <div className="bg-black h-[5px] w-[35px] rounded-md"></div>
                        <div className="bg-black h-[5px] w-[35px] rounded-md"></div>
                        <div className="bg-black h-[5px] w-[35px] rounded-md"></div>
                    </button>
                </div>
            </div>
            <div className="h-[90%] bg-[#efefef] p-4 overflow-y-scroll">{props.children}</div>
        </div>
    </div>
    );
}