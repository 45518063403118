import { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { getUserProfile, getUserTransactions, userAceivePlans } from "../../utilities/authUtil";
import { Link } from "react-router-dom";
// import moment from "mo";


export default function UserHomepage() {

    const [userDetails, setUserDetails] = useState({});
    const [transactions, setTransactions] = useState("loading");
    const [activePlanCount, setActivePlanCount] = useState(0);
    const [page, setPage] = useState(1);

    const loadDashboard = async () => {
        const userProfile = await getUserProfile();
        const transactions  = await getUserTransactions();
        const activePlans = await userAceivePlans();
        setUserDetails(userProfile.response.data);
        setTransactions(transactions.response.data);
        setActivePlanCount(activePlans.response.data.user_active_plans.total_documents);
        console.log(activePlans.response.data.user_active_plans.total_documents);
    }

    useEffect(() => {
        loadDashboard();
    }, []);

    return (
        <Layout>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6">
                <div className="w-full flex flex-col justify-between gap-4 rounded-md border-[2px] p-4 bg-white font-bold border-solid border-[gold]">
                    <div>Current Plan(s)</div>
                    <div className="flex justify-between items-center">
                        <div className="text-[24px]">{activePlanCount}</div>
                        <Link className="no-underline text-black" to="/dashboard/planshistory">View Plan(s) History</Link>
                    </div>
                </div>
                {/* <div className="w-full rounded-md border-[2px] p-4 bg-white font-bold border-solid border-[blue]">
                    <div>Investment</div>
                    <div className="text-[24px]">N400,000</div>
                </div> */}
                {/* <div className="w-full rounded-md border-[2px] p-4 bg-white font-bold border-solid border-[green]">
                    <div>Current plan(s) total profit</div>
                    <div className="text-[24px]">N0.0</div>
                </div> */}
                {/* <div className="w-full rounded-md border-[2px] p-4 bg-white font-bold border-solid border-[blue]">
                    <div>Months Left</div>
                    <div className="text-[24px]">--</div>
                </div> */}
                <div className="w-full flex flex-col justify-between gap-4 rounded-md border-[2px] p-4 bg-white font-bold border-solid border-[brown]">
                    <div>Account Balance</div>
                    <div className="flex justify-between items-center">
                        <div className="text-[24px]">N{userDetails.user_balance}</div>
                        <Link className="no-underline text-black" to="/dashboard/deposit">Deposit</Link>
                    </div>
                </div>
                {/* <div className="w-full rounded-md border-[2px] p-4 bg-white font-bold border-solid border-[green]">
                    <div>Savings</div>
                    <div className="text-[24px]">N10,000</div>
                </div> */}
            </div>

            <div className="mt-20 mb-4">
                <div className="flex justify-between items-center py-4">
                    <div className="font-bold border-b border-1 border-2 border-[#fff] text-[24px]">Transaction History</div>
                    <div className="flex gap-4">
                        <button>Previous</button>
                        <div>Page {transactions.current_page} of {transactions.total_pages}</div>
                        <button>Next</button>
                    </div>
                </div>



                <div className="flex flex-col">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <table className="min-w-full text-left text-sm font-light">
                                    <thead className="border-b border-1 border-[#fff] font-medium dark:border-b border-1 border-[black]">
                                        <tr>
                                            <th scope="col" className="px-6 py-4">#</th>
                                            <th scope="col" className="px-6 py-4">Amount</th>
                                            <th scope="col" className="px-6 py-4">Date</th>
                                            <th scope="col" className="px-6 py-4">Paystack Ref</th>
                                        </tr>
                                    </thead>



                                    {transactions.transactions ? transactions.transactions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((transaction, index) => {
                                        return (
                                            <tbody key={index}>
                                        <tr
                                            className="transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-b border-2 border-[#fff]">
                                            <td className="whitespace-nowrap px-6 py-4 font-medium">{index + 1}</td>
                                            <td className="whitespace-nowrap px-6 py-4">{transaction.amount}</td>
                                            <td className="whitespace-nowrap px-6 py-4">{transaction.createdAt}</td>
                                            <td className="whitespace-nowrap px-6 py-4">{transaction.paystack_transaction_reference}</td>
                                        </tr>
                                    </tbody>
                                        );
                                    }) : transactions == "loading" ? <tbody><tr><td colSpan={4} className="text-center">Loading</td></tr></tbody> : <tbody><tr><td colSpan={4} className="text-center">No Transaction</td></tr></tbody>}
                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </Layout>
    );
}