import { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import {PaystackButton} from "react-paystack"
import { getUserProfile, updateUserBalance } from "../../utilities/authUtil";
import Joi from "joi";
import { ToastContainer, toast } from 'react-toastify';

export default function Deposit() {

    const paystackKey = process.env.REACT_APP_PAYSTACK_KEY;

    const [userEmail, setUserEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [amount, setAmount] = useState("");


    const paystackComponent = {
        publicKey: paystackKey,
        email: userEmail,
        amount: amount * 100,
        metadata: {
            name: userName,
            email: userEmail
        },
        text: "Deposit",
        onSuccess: (response) => {
            console.log(response);
            if(response.message == "Approved" && response.status == "success") {
                console.log(response);
                updateUserBalance({
                    paystack_transaction_reference: response.reference,
                    amount
                })
                .then((balanceUpdateResponse) => {
                    if(response.status == 200 && response.data.is_successful == true) {

                    }
                    // console.log("Balance update =", balanceUpdateResponse);
                    toast.success("Deposit Successful");
                })
                .catch(error => {
                    console.log(error);
                });
            }
        }
    };

    const getProfile = async () => {
        const profile = await getUserProfile();
        setUserEmail(profile.response.data.email);
        setUserName(profile.response.data.full_name);
    }

    useEffect(() => {
        getProfile();
    }, []);


    return (
        <Layout>
            <ToastContainer />
            <div className="flex flex-col gap-8 items-start">
                <div className="font-bold">Amount:</div>
                <input type="number" className="py-2 px-1 w-1/4" placeholder="Input an amount" onChange={(e) => {setAmount(e.target.value)}} />
                {amount.length <= 0 ? <button className="py-2 px-1 w-1/4 text-white bg-[#bbb] border-0 font-bold">Deposit</button> : <PaystackButton className="py-2 px-1 w-1/4 text-white bg-blue border-0 font-bold" {...paystackComponent} />}
                
            </div>
        </Layout>
    );
}