import axios from "axios";

const paystackPublicKey = process.env.REACT_APP_PAYSTACK_KEY;

const backendV1 = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASEURL
});

const backendV1WithAuth = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_BASEURL,
    headers: {
        "auth-key": "Bearer " + localStorage.getItem("token")
    }
});

const paystackRequest = axios.create({
    headers: {
        Authorization: `Bearer ${paystackPublicKey}`
    }
});

export {
    backendV1, backendV1WithAuth, paystackRequest
}