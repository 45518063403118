import { useState } from "react";
import { Link } from "react-router-dom";



export default function Home() {

    const [showMenu, setShowMenu] = useState(false);

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    }

    return (
        <div>
            <div className="flex justify-between px-4 md:px-20 h-[60px] items-center">
                <div>Logo</div>
                <div className={`flex md:hidden flex-col gap-1 h-full w-[40px] justify-center`} onClick={toggleMenu}>
                    <div className="h-[5px] rounded-[2px] bg-black w-full"></div>
                    <div className="h-[5px] rounded-[2px] bg-black w-full"></div>
                    <div className="h-[5px] rounded-[2px] bg-black w-full"></div>
                </div>
                <div className={`${showMenu ? "flex flex-col absolute w-full left-0 top-[60px] gap-2 items-stretch text-center bg-white" : "hidden"} md:flex font-bold no-underline md:h-full items-center`}>

                    <Link className="no-underline h-[40px] md:h-full flex items-center px-4 text-black hover:bg-green hover:text-white transition ease-in-out duration-500" to="/login">Home</Link>
                    <Link className="no-underline h-[40px] md:h-full flex items-center px-4 text-black hover:bg-green hover:text-white transition ease-in-out duration-500" to="/login">Contact</Link>
                    <Link className="no-underline h-[40px] flex items-center px-4 md:h-full text-black hover:bg-green hover:text-white transition ease-in-out duration-500" to="/login">How it works</Link>
                    <Link className="no-underline h-[40px] md:h-full flex items-center px-4 text-black hover:bg-green hover:text-white transition ease-in-out duration-500" to="/login">Login</Link>
                    <Link className="no-underline bg-green h-[40px] md:h-full flex items-center px-4 text-white hover:bg-white hover:text-green transition ease-in-out duration-500" to="/register">Register</Link>
                </div>
            </div>

            <div style={{ backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/forex-pic.jpg)", height: "calc(100vh - 60px)", width: "100%", backgroundSize: "cover" }} className="p-4 md:p-20 flex items-center">
                <div className="w-full md:w-1/2 flex flex-col gap-4 text-center md:text-left">
                    <div className="font-bold text-[40px] md:text-[80px]  text-white">
                        Global Market Leader in Investment
                    </div>
                    <div className="text-white text-lg">
                        Invest with confidence and benefit from the reliability of investment/trading experts with proven record of stability and strength.
                    </div>
                    <div className="flex justify-center md:justify-start gap-4">
                        <Link to="/register" className="no-underline px-8 py-4 bg-green hover:bg-[rgb(2,41,17)] cursor-pointer text-white border-0 rounded-sm font-lg font-bold transition ease-in-out duration-500">Create an account</Link>
                        <Link to="/login" className="no-underline px-8 py-4 text-white border-white  border-[2px] border-solid bg-white/0  hover:text-green rounded-sm font-lg font-bold transition ease-in-out duration-500">Login</Link>
                    </div>
                </div>
            </div>

            <div className="flex gap-20 px-4 py-10 md:px-20 md:py-40 items-center">
                <div className="w-full md:w-1/2">
                    <div className="text-center md:text-left font-bold text-[40px] md:text-[80px]">
                        Tested, Trusted and Reliable
                    </div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis, magna non dapibus vehicula, quam sem venenatis nunc, vel tempor massa velit vitae felis. Sed tincidunt lobortis lorem, quis tristique augue dignissim at. Proin lacinia lobortis nisi in feugiat. Praesent dapibus libero risus, id imperdiet nibh sagittis vel. Mauris sollicitudin, augue sit amet feugiat ornare, mi velit dapibus eros, ut ultrices libero arcu id magna. In felis odio, feugiat luctus nulla consequat, euismod molestie lacus. Vivamus at commodo odio. Nam id nisi id mauris mollis porta sit amet et metus. Phasellus vitae diam eget ligula blandit convallis ultrices vel purus. Curabitur quis mattis erat.
                    </div>
                    <div className="pt-4">

                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis, magna non dapibus vehicula, quam sem venenatis nunc, vel tempor massa velit vitae felis. Sed tincidunt lobortis lorem, quis tristique augue dignissim at. Proin lacinia lobortis nisi in feugiat. Praesent dapibus libero risus, id imperdiet nibh sagittis vel. Mauris sollicitudin, augue sit amet feugiat ornare, mi velit dapibus eros, ut ultrices libero arcu id magna. In felis odio, feugiat luctus nulla consequat, euismod molestie lacus. Vivamus at commodo odio. Nam id nisi id mauris mollis porta sit amet et metus. Phasellus vitae diam eget ligula blandit convallis ultrices vel purus. Curabitur quis mattis erat.
                    </div>
                </div>
                <div style={{ backgroundImage: "url(crypto.png)", height: "60vh", backgroundSize: "cover" }} className="w-1/2 rounded-md hidden md:block"></div>
            </div>

            <div className="px-4 py-10 md:p-20 bg-grey">
                <div className="text-center my-10 text-[40px] md:text-[80px] font-bold">How it works</div>
                <div className="flex flex-col md:flex-row gap-10">
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis, magna non dapibus vehicula, quam sem venenatis nunc, vel tempor massa velit vitae felis. Sed tincidunt lobortis lorem, quis tristique augue dignissim at. Proin lacinia lobortis nisi in feugiat. Praesent dapibus libero risus, id imperdiet nibh sagittis vel. Mauris sollicitudin, augue sit amet feugiat ornare, mi velit dapibus eros, ut ultrices libero arcu id magna. In felis odio, feugiat luctus nulla consequat, euismod molestie lacus. Vivamus at commodo odio. Nam id nisi id mauris mollis porta sit amet et metus. Phasellus vitae diam eget ligula blandit convallis ultrices vel purus. Curabitur quis mattis erat.
                    </div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis, magna non dapibus vehicula, quam sem venenatis nunc, vel tempor massa velit vitae felis. Sed tincidunt lobortis lorem, quis tristique augue dignissim at. Proin lacinia lobortis nisi in feugiat. Praesent dapibus libero risus, id imperdiet nibh sagittis vel. Mauris sollicitudin, augue sit amet feugiat ornare, mi velit dapibus eros, ut ultrices libero arcu id magna. In felis odio, feugiat luctus nulla consequat, euismod molestie lacus. Vivamus at commodo odio. Nam id nisi id mauris mollis porta sit amet et metus. Phasellus vitae diam eget ligula blandit convallis ultrices vel purus. Curabitur quis mattis erat.
                    </div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis, magna non dapibus vehicula, quam sem venenatis nunc, vel tempor massa velit vitae felis. Sed tincidunt lobortis lorem, quis tristique augue dignissim at. Proin lacinia lobortis nisi in feugiat. Praesent dapibus libero risus, id imperdiet nibh sagittis vel. Mauris sollicitudin, augue sit amet feugiat ornare, mi velit dapibus eros, ut ultrices libero arcu id magna. In felis odio, feugiat luctus nulla consequat, euismod molestie lacus. Vivamus at commodo odio. Nam id nisi id mauris mollis porta sit amet et metus. Phasellus vitae diam eget ligula blandit convallis ultrices vel purus. Curabitur quis mattis erat.
                    </div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis, magna non dapibus vehicula, quam sem venenatis nunc, vel tempor massa velit vitae felis. Sed tincidunt lobortis lorem, quis tristique augue dignissim at. Proin lacinia lobortis nisi in feugiat. Praesent dapibus libero risus, id imperdiet nibh sagittis vel. Mauris sollicitudin, augue sit amet feugiat ornare, mi velit dapibus eros, ut ultrices libero arcu id magna. In felis odio, feugiat luctus nulla consequat, euismod molestie lacus. Vivamus at commodo odio. Nam id nisi id mauris mollis porta sit amet et metus. Phasellus vitae diam eget ligula blandit convallis ultrices vel purus. Curabitur quis mattis erat.
                    </div>
                </div>
            </div>

            <div className="px-4 py-10 md:p-20">
                <div className="text-center my-10 text-[40px] md:text-[80px] font-bold">Why Investors Choose Us</div>
                <div className="flex flex-col md:flex-row gap-10">
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis, magna non dapibus vehicula, quam sem venenatis nunc, vel tempor massa velit vitae felis. Sed tincidunt lobortis lorem, quis tristique augue dignissim at. Proin lacinia lobortis nisi in feugiat. Praesent dapibus libero risus, id imperdiet nibh sagittis vel. Mauris sollicitudin, augue sit amet feugiat ornare, mi velit dapibus eros, ut ultrices libero arcu id magna. In felis odio, feugiat luctus nulla consequat, euismod molestie lacus. Vivamus at commodo odio. Nam id nisi id mauris mollis porta sit amet et metus. Phasellus vitae diam eget ligula blandit convallis ultrices vel purus. Curabitur quis mattis erat.
                    </div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis, magna non dapibus vehicula, quam sem venenatis nunc, vel tempor massa velit vitae felis. Sed tincidunt lobortis lorem, quis tristique augue dignissim at. Proin lacinia lobortis nisi in feugiat. Praesent dapibus libero risus, id imperdiet nibh sagittis vel. Mauris sollicitudin, augue sit amet feugiat ornare, mi velit dapibus eros, ut ultrices libero arcu id magna. In felis odio, feugiat luctus nulla consequat, euismod molestie lacus. Vivamus at commodo odio. Nam id nisi id mauris mollis porta sit amet et metus. Phasellus vitae diam eget ligula blandit convallis ultrices vel purus. Curabitur quis mattis erat.
                    </div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam mollis, magna non dapibus vehicula, quam sem venenatis nunc, vel tempor massa velit vitae felis. Sed tincidunt lobortis lorem, quis tristique augue dignissim at. Proin lacinia lobortis nisi in feugiat. Praesent dapibus libero risus, id imperdiet nibh sagittis vel. Mauris sollicitudin, augue sit amet feugiat ornare, mi velit dapibus eros, ut ultrices libero arcu id magna. In felis odio, feugiat luctus nulla consequat, euismod molestie lacus. Vivamus at commodo odio. Nam id nisi id mauris mollis porta sit amet et metus. Phasellus vitae diam eget ligula blandit convallis ultrices vel purus. Curabitur quis mattis erat.
                    </div>
                </div>
            </div>

            <div className="bg-black grid grid-cols-2 gap-y-10 md:grid-cols-4 text-white px-4 md:px-20 py-10">
                <div>
                    <div className="font-bold text-xl mb-4">Quick Links</div>
                    <div className="flex flex-col">
                        <Link className="no-underline text-white">Home</Link>
                        <Link className="no-underline text-white">Contact</Link>
                        <Link className="no-underline text-white">How it works</Link>
                        <Link className="no-underline text-white">Login</Link>
                        <Link className="no-underline text-white">Register</Link>
                    </div>
                </div>

                <div>
                    <div className="font-bold text-xl mb-4">Social Links</div>
                    <div className="flex flex-col">
                        <Link className="no-underline text-white">Facebook</Link>
                        <Link className="no-underline text-white">Twitter</Link>
                        <Link className="no-underline text-white">Instagram</Link>
                    </div>
                </div>

                <div>
                    <div className="font-bold text-xl mb-4">Social Links</div>
                    <div className="flex flex-col">
                        <Link className="no-underline text-white">Facebook</Link>
                        <Link className="no-underline text-white">Twitter</Link>
                        <Link className="no-underline text-white">Instagram</Link>
                    </div>
                </div>

                <div>
                    <div className="font-bold text-xl mb-4">Social Links</div>
                    <div className="flex flex-col">
                        <Link className="no-underline text-white">Facebook</Link>
                        <Link className="no-underline text-white">Twitter</Link>
                        <Link className="no-underline text-white">Instagram</Link>
                    </div>
                </div>
            </div>

            <div className="bg-black text-white text-center p-4">
                All Right Reserved &copy; {new Date().getFullYear()}
            </div>

        </div>
    );
}