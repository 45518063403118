import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import joi from "joi";
import { loginUser } from "../utilities/authUtil";
import { ToastContainer, toast } from 'react-toastify';


export default function Login() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [login, setLogin] = useState("Login");

    const navigate = useNavigate();

    const loginAction = async () => {
        try {
            setLogin("Please wait");
            const loginForm = { email, password }
            const loginSchema = joi.object({
                email: joi.string().email({ tlds: { allow: false } }).required(),
                password: joi.string().min(8).alphanum().required()
            });

            const { error: formValidationError } = loginSchema.validate(loginForm);

            if (formValidationError) {
                toast.error(formValidationError.message);
                return;
            }

            const { error, response, succesful } = await loginUser(loginForm);

            console.log(response);
            if (response.data.status_code == 200 && succesful == true && !error) {
                // navigate("/login");
                if (response.data.user_details.email_verified == false) {
                    navigate("/verify-email/" + response.data.unique_id);
                } else {
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("role", response.data.user_details.role);
                    if (response.data.user_details.role == "user") {
                        navigate("/dashboard");
                    } else {
                        navigate("/admindashboard");
                    }
                }
            } else {
                console.log(response);
                toast.error(response.data.detail);
            }

        } catch (error) {
            toast.error(error.message || "An error occurred!");
        } finally {
            setLogin("Login");
        }
    }

    const inputStyle = " px-4 py-4 border-none rounded-[4px] bg-black/[0.1]";
    const loginButtonStyle = "bg-green p-4 border-none font-bold text-white rounded-[4px] text-[16px]";

    return (
        <div className="h-screen w-full flex justify-center items-center">
            <ToastContainer />
            <div className="w-[90%] md:w-1/2 xl:w-1/3 flex flex-col gap-10">

                {/* <img /> */}

                <h2>Login Form</h2>

                <div className="flex flex-col border-box">
                    <small className="font-bold">Email</small>
                    <input type="email" placeholder="example.com" className={inputStyle} onChange={(e) => { setEmail(e.target.value) }} />
                </div>

                <div className="flex flex-col border-box">
                    <small className="font-bold">Password</small>
                    <input type="password" placeholder="********" className={inputStyle} onChange={(e) => { setPassword(e.target.value) }} />
                </div>
                <button className={loginButtonStyle} onClick={loginAction}>{login}</button>

                <div className="flex flex-col text-center gap-2">
                    <small>
                        Forgot Password? <Link className="no-underline text-blue">Reset Password</Link>
                    </small>

                    <small>
                        Don't have an account? <Link to="/register" className="no-underline text-blue">Register here</Link>
                    </small>
                </div>



            </div>
        </div>
    );
}