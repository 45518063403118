import { useLocation, useNavigate } from "react-router-dom";

export default function UserSideBar() {

    const location = useLocation();
    const navigate = useNavigate();

    const buttons = [
        {
            value: "Dashboard",
            path: "/dashboard"
        },
        {
            value: "Profile",
            path: "/dashboard/profile"
        },
        {
            value: "Plans",
            path: "/dashboard/plans"
        },
        {
            value: "Profit Calculator",
            path: "/dashboard/profitcalculator"
        },
        {
            value: "Deposit",
            path: "/dashboard/deposit"
        },
        {
            value: "Withdraw",
            path: "/dashboard/withdraw"
        },
        {
            value: "Log Out",
            path: "/log-out"
        }
    ];


    const navigateTo = (route) => {
        if(route == "/log-out") {

            localStorage.removeItem("token");
            localStorage.removeItem("role");
            navigate("/login");
        } else {
            navigate(route);
        }
    }

    

    return (
        <div className="flex flex-col gap-6 mt-20">
            {buttons.map((button, index) => {
                return (
                    <button
                    className={`border-none py-4 text-left rounded-md pl-4 text-lg text-white font-bold cursor-pointer ${location.pathname == button.path ? "bg-[white]/[0.2]" : "bg-white/[0.0] hover:bg-[white]/[0.1]"}`}
                    onClick={() => {navigateTo(button.path)}}
                    key={index}
                    >
                        {button.value}
                    </button>
                );
            })}
        </div>
    );
}